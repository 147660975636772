import styled from "styled-components";

import ourServicesBedroom from "./assets/our_services_bedroom.webp";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1366,
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_740,
  MOBILE_460,
  MOBILE_380,
} from "../../styles/globals/sizes";

export const HomeCareServices = styled.div`
  padding: 100px calc(15px + 2vw);
  background-image: url(${ourServicesBedroom});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width:100%;
  max-width: 1500px;
  margin: auto;

  /* @media (max-width: ${DESKTOP_1200}px) {
    padding: 100px 50px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    padding: 100px 30px;
  }
  @media (max-width: ${TABLET_992}px) {
    padding: 50px 50px 10px;
  }
  @media (max-width: ${TABLET_740}px) {
    padding: 50px 20px 10px;
  } */
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 20px 5px;
  }
`;

export const ServicesTitle = styled.h3`
  margin-bottom: 20px;
  font: 500 37px/49px Roboto;
  color: ${COLORS.vermilion};
  text-align: center;
  @media (max-width: ${TABLET_992}px) {
    text-align: center;
  }
  @media (max-width: ${TABLET_740}px) {
    text-align: left;
    margin: 0 auto 20px auto;
    max-width: 620px;
    font: 600 35px/45px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 600 29px/34px Roboto;
  }
  @media (max-width: ${MOBILE_380}px) {
    font: 600 24px/30px Roboto;
  }
`;

export const ServicesText = styled.p`
  max-width: 620px;
  font: 500 20px/28px Roboto;
  color: ${COLORS.vermilion};
  text-align: center;
  align-self: center;
  @media (max-width: ${TABLET_740}px) {
    text-align: left;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 18px/24px Roboto;
  }
  @media (max-width: ${MOBILE_380}px) {
    font: 500 16px/24px Roboto;
  }
`;

export const ServicesGrid = styled.div`
  width: 60%;
  padding-top: 60px;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px 50px;
  @media (max-width: ${DESKTOP_1366}px) {
    width: 70%;
    padding: 30px 0 0 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    width: 80%;
    padding: 20px 0px;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    padding: 40px 0;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
    margin: auto;
    max-width: 30em;
  }
`;

export const HomeCareService = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 1fr;
  align-items: center;
  width: 100%;
  @media (max-width: ${TABLET_740}px) {
    grid-template-columns: 60px 1fr 1fr;
  }
`;

export const HomeCareServiceImage = styled.div`
  max-height: 60px;
  min-height: 60px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) center/contain no-repeat` : "none"};
  grid-column: 1 / 2;
`;

export const HomeCareServiceTextContainer = styled.div`
  grid-column: 2 / 4;
  @media (max-width: ${DESKTOP_1024}px) {
    padding-left: 10px;
  }
`;

export const HomeCareServiceTitle = styled.h4`
  font: 500 18px/20px Roboto;
  color: ${COLORS.vermilion};
  margin-bottom: 5px;
  text-align: left;
`;

export const HomeCareServiceText = styled.h4`
  font: 400 15px/17px Roboto;
  color: ${COLORS.gray};
  text-align: left;
`;

/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "./input/index";

import BackImage from "./assets/callback.webp";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Text,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxInput,
  CheckboxText,
  CheckboxWrapper,
  ModalDescription,
  CheckboxWrapperLong,
  CheckboxInputLong,
} from "./styles";
import ModalForm from "../ModalForm";
import { PATHS } from "../../config/urls";

const ContactForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    setError(false);
    const { name } = event.target;
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail && inputs.privacyPolicy) {
      try {
        await axios.post(`${API_URI}/api/home-care/contactMail/`, inputs);
        setSuccesForm(true);
        setInputs({});
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setError(false);
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  const handlePrivacyPolicyChange = () => {
    setError(false);
    setInputs((values) => ({
      ...values,
      privacyPolicy: !inputs.privacyPolicy,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (succesForm) {
      window.location.href = PATHS.THANKYOU;
    }
  }, [succesForm]);

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>REQUEST A CALL BACK</Title>
          <Line />
          <Text>We will call you back within 24 hours</Text>
          <Form onSubmit={handleSubmit}>
            <InputForm
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              errorMsg="Invalid name"
              required
              value={inputs.name || ""}
              onChange={handleChange}
            />
            <InputForm
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              errorMsg="Invalid email"
              required
              value={inputs.email || ""}
              onChange={handleChange}
            />
            <InputForm
              id="phone"
              type="phone"
              name="phone"
              placeholder="Phone"
              errorMsg="Invalid phone"
              required
              value={inputs.phone || ""}
              onChange={handleChange}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
                style={{ marginRight: "10px" }}
              />
              <CheckboxText>
                I consent to receive information from Life Home Care
              </CheckboxText>
            </CheckboxWrapper>
            <CheckboxWrapperLong>
              <CheckboxInputLong
                type="checkbox"
                onChange={handlePrivacyPolicyChange}
                style={{ marginRight: "10px" }}
              />
              <CheckboxText>
                I have read and agree to Life Home Care{" "}
                <span
                  onClick={handleModalToggle}
                  style={{
                    color: "white",
                    zIndex: 150,
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  SMS privacy policy
                </span>
              </CheckboxText>
            </CheckboxWrapperLong>
            {showError && (
              <FormMessage>
                Please consent to receive information and agree to the privacy
                policy before sending request
              </FormMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send Request </Button>
              {errorForm && (
                <FormMessage>Error. Please try again later</FormMessage>
              )}
            </BottomSection>
          </Form>
        </TextContent>
      </TextContainer>
      <ModalForm isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: "bold" }}>Privacy Notice: </span>
          When you opt-in to receive SMS messages from us, we want to assure you
          that we respect your privacy. We do not share or sell your phone
          number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us,
          and we use your information solely for the purpose of sending you
          relevant SMS messages as requested. If you have any questions or
          concerns, feel free to contact us at{" "}
          <span style={{ color: "#0000ff", textDecoration: "underline" }}>
            hello@homecare.life
          </span>
        </ModalDescription>
      </ModalForm>
      <ImageContainer>
        <Picture>
          <img src={BackImage} alt="group of people" />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactForm;

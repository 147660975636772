import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import {
  TABLET_992,
  TABLET_800,
  MOBILE_460,
  DESKTOP_1024,
  DESKTOP_1200,
} from "../../styles/globals/sizes";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  margin: auto;

  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: transparent
    linear-gradient(270deg, ${COLORS.magenta} 0%, ${COLORS.redTitle} 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 30px 0 50px;
  }
`;

export const TextContent = styled.div`
  width: 80%;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 580px;
  @media (max-width: ${TABLET_992}px) {
    max-height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 488px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 320px;
  }
`;

export const Picture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    @media (max-width: ${TABLET_800}px) {
      object-position: top left;
    }
  }
`;

export const Title = styled.div`
  font: normal normal normal 37px/49px Roboto;
  color: ${COLORS.white};

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 30%;
  border-bottom: 4px solid ${COLORS.white};
  margin: 20px 0;
`;

export const Text = styled.div`
  font: normal normal 400 16px/20px Roboto;
  color: ${COLORS.white};
  margin-bottom: 27px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  background: ${COLORS.white}5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  height: 48px;
  margin-top: 22px;

  ::placeholder {
    color: ${COLORS.white};
  }
`;

export const Button = styled.button`
  background: ${COLORS.white} 0% 0% no-repeat padding-box;
  border-radius: 24px;
  color: ${COLORS.redTitle};
  width: fit-content;
  padding: 12px 80px;
  border: none;
  font-size: 18px;
  white-space: nowrap;
  @media (max-width: 1030px) {
    margin-bottom: 10px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 43px;

  @media (max-width: 1030px) {
    flex-direction: column;
    margin: 40px auto 0;
  }
`;

export const FormMessage = styled.div`
  color: ${COLORS.white};
  margin: auto;
  margin-left: 20px;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;
  @media (max-width: ${DESKTOP_1024}px) {
    display: flex;
    align-items: flex-start;
  }
`;
export const CheckboxWrapperLong = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 5px;
  @media (max-width: 1135px) {
    display: flex;
    align-items: flex-start;
`;
export const CheckboxInputLong = styled.input`
  margin-right: 5px;
  @media (max-width: 1135px) {
    margin-top:10px;
`;
export const CheckboxInput = styled.input`
  margin-right: 5px;
  @media (max-width: ${DESKTOP_1024}px) {
    margin-top: 10px;
  }
`;

export const CheckboxText = styled.span`
  font: normal 16px/28px Roboto;
  color: ${COLORS.white};
  margin-right: 10px;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 14px/28px Roboto;
  }
`;
export const ModalDescription = styled.p`
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
`;

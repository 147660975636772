import React from "react";

import {
  CredentialsBackground,
  CredentialsContainer,
  CredentialInfo,
  CredentialText,
  CredentialPicture,
} from "./styles";

import credentialAvif from "../../images/company-credentials/avif/CACH_Logo.avif";
import credentialPng from "../../images/company-credentials/png/CACH_Logo.png";

const Credential = () => {
  return (
    <CredentialsBackground>
      <CredentialsContainer>
        <CredentialInfo>
          <CredentialText>
            <span>Life Home Care</span> is a licensed and accredited health care
            firm that directly employs Certified Home Health Aides that are
            working under the direction of a Registered Nurse. We are licensed
            by the NJ Division of Consumer Affairs and Accredited with
            Distinction by the{" "}
            <span>Commission on Accreditation for Home Care</span>.
          </CredentialText>
        </CredentialInfo>
        <CredentialPicture>
          <source srcset={credentialAvif} type="image/avif" />
          <img
            srcset={credentialPng}
            alt="New Jersey C.A.H.C. credential"
            type="image/png"
          />
        </CredentialPicture>
      </CredentialsContainer>
    </CredentialsBackground>
  );
};

export default Credential;

/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import AOS from "aos";

import { CONTACT } from "../config/urls";
import { HelmetProvider } from "react-helmet-async";

import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import OurServices from "../components/OurServices";
import ContactForm from "../components/contactForm";
import LifeHomeCareLocation from "../components/GoogleMaps";
import ServicesCards from "../components/ServicesCards";
import GetInTouchSection from "../components/GetInTouchSection";
import Credential from "../components/Credential";
import Testimonials from "../components/Testimonials/index.tsx";

import seoImage from "../../static/seo/seo_image.png";
import phone from "../images/home-care/Tel.svg";
import HeroWebp from "../images/home-care/homecare-hero.webp";
import groupOfPeople from "../images/home-care/MaskGroup.webp";

import {
  HeroContainer,
  ContactUsPhone,
  InternalWrapper,
  MobileDiv,
  HeroTextDiv,
  Title,
  Paragraph,
  PhoneAndParagraph,
  CardTitle,
  WhiteLine,
  CardParagraph,
  TwoDivsContainer,
  TextContainer,
  TextContent,
  ImageContainer,
  ImageContent,
  HeroPicture,
  PhoneContainer,
  PhoneNumber,
  Icon,
} from "../styles/pages/index.jsx";

const Home = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(
    () => {
      AOS.init({
        // initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout>
        <Seoinfo
          siteName="Compassionate Care in the Comfort of Your Home | Life Home Care"
          titlePage="Compassionate Care in the Comfort of Your Home | Life Home Care"
          description="Our goal is to quality of life and promote independence for our clients by providing compassionate care. Life is the top home care   agency in the area."
          keywords="Home Care Services,Senior Care,In-Home Healthcare,Elderly Assistance,Personal Care Services"
          type="website"
          url="/"
          imageFacebook={seoImage}
          canonical
        />
        <HeroContainer bgImage={HeroWebp} alt="Woman hugging elder woman">
          <HeroPicture>
            <source srcSet={HeroWebp} type="image/webp" />
            <img src={HeroWebp} alt="group of people" />
          </HeroPicture>
          <InternalWrapper data-aos="fade">
            <MobileDiv bgImage={HeroWebp} alt="Woman hugging elder woman" />
            <HeroTextDiv>
              <Title>Compassionate Care in the comfort of your home</Title>
              <PhoneAndParagraph>
                <ContactUsPhone href="#callBack">Get Started</ContactUsPhone>
                <ContactUsPhone mobile href={`tel:${CONTACT.PHONE}`}>
                  {CONTACT.PHONE_TO_DISPLAY}
                </ContactUsPhone>
                <Paragraph>
                  Our goal is to promote independence for our clients by
                  providing compassionate care in the comfort of their homes.
                </Paragraph>
                <PhoneContainer>
                  <Icon src={phone} />
                  <PhoneNumber>973-273-3441</PhoneNumber>
                </PhoneContainer>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <TwoDivsContainer>
          <ImageContainer>
            <ImageContent>
              <source srcSet={groupOfPeople} type="image/webp" />
              <img src={groupOfPeople} alt="group of people" />
            </ImageContent>
          </ImageContainer>
          <TextContainer>
            <TextContent>
              <CardTitle>
                WELCOME TO LIFE HOME CARE, YOUR HOME CARE AGENCY
              </CardTitle>
              <WhiteLine />
              <CardParagraph>
                Often individuals require assistance with one or more of their
                activities of daily living such as bathing, dressing, and
                ambulation. Tasks such as meal preparation or laundry can be
                difficult after a recent surgery or hospitalization. As we age,
                we often become in need of reminders to take medications or to
                eat properly.
              </CardParagraph>
            </TextContent>
          </TextContainer>
        </TwoDivsContainer>
        <OurServices />
        <Credential />
        <Testimonials />
        <ContactForm />
        <LifeHomeCareLocation />
        <ServicesCards />
        <GetInTouchSection contactEmail={CONTACT.MAIL} />
      </Layout>
    </HelmetProvider>
  );
};

export default Home;
